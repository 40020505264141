@import './src/scss/variables';
@import './src/scss/app';

.widget {
  &__image-preview {
    max-width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;

    &_default {
      cursor: default;
      width: 100%;
      height: 100%;
    }
  }
}